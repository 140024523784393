import React, { useContext } from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { FaAlignRight } from "react-icons/fa"
import { Link } from "gatsby"
import { GatsbyContext } from "../context/context"

const query = graphql`
  {
    logo: allPrismicImages(
      filter: { uid: { eq: "vermont-web-development-long-logo-black" } }
    ) {
      nodes {
        uid
        data {
          image {
            alt
            fixed(width: 200) {
              ...GatsbyPrismicImageFixed
            }
          }
        }
      }
    }
  }
`

const Navbar = () => {
  const { isSidebarOpen, links, toggleSidebar } = useContext(GatsbyContext)

  const {
    logo: { nodes: logo },
  } = useStaticQuery(query)
  const { alt } = logo[0].data.image
  const fixed = logo[0].data.image.fixed

  return (
    <nav className="navigation">
      <div className="navigation__center">
        <div className="navigation__header">
          <Link to="/">
            {/* <img src={logo} alt="design" width="130" height="50" /> */}
            <Image
              fixed={fixed}
              fadeIn={false}
              loading="eager"
              className="hero-img"
              alt={alt}
              className="hero-img"
            />
          </Link>
          {!isSidebarOpen && (
            <button
              className="navigation__toggle--btn"
              aria-label="toggle-button"
              onClick={toggleSidebar}
            >
              <FaAlignRight />
            </button>
          )}
        </div>

        {/* <ul className="navigation__list">
          {links.map(link => {
            return (
              <li key={link.id}>
                <Link to={link.url}>{link.text}</Link>
              </li>
            )
          })}
        </ul> */}
      </div>
    </nav>
  )
}

export default Navbar
