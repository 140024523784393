import React, { useContext } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { BsFillEnvelopeFill } from "react-icons/bs"
import { IoCall } from "react-icons/io5"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import { GatsbyContext } from "../../context/context"
import socialLinks from "../../constants/socials"
import industries from "../../constants/industries"

const query = graphql`
  {
    logo: allPrismicImages(
      filter: {
        data: { image: { alt: { eq: "vermont web development logo" } } }
      }
    ) {
      nodes {
        uid
        data {
          image {
            alt
            fixed(width: 200) {
              ...GatsbyPrismicImageFixed
            }
          }
        }
      }
    }
  }
`

const FooterTop = () => {
  const { links } = useContext(GatsbyContext)

  const {
    logo: { nodes: logo },
  } = useStaticQuery(query)
  const { alt } = logo[0].data.image
  const fixed = logo[0].data.image.fixed

  return (
    <section id="footer-top" className="footer-top u-margin-top-big u-padding">
      <div className="row">
        <div className="col-4-of-4">
          <article>
            <nav>
              <h3 className="heading-secondary--small u-margin-bottom-small">
                Quick Links
              </h3>
              <nav className="navigation">
                <ul className="footer-top__links">
                  {links.map(link => {
                    return (
                      <li key={link.id}>
                        <a href={link.url}>
                          {link.text.slice(0, 1).toUpperCase() +
                            link.text.slice(1, link.text.length)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </nav>
          </article>

          <article>
            <h3 className="heading-secondary--small u-margin-bottom-small">
              Industries I Serve
            </h3>

            <ul className="footer-top__text__nolink">
              {industries.map(industry => {
                return <li key={industry.id}>{industry.label}</li>
              })}
            </ul>
          </article>

          <article>
            <h3 className="heading-secondary--small u-margin-bottom-small">
              Contact
            </h3>
            <p className="footer-top__text">
              <a
                href="mailto:contact@vtwebdevelopment.com"
                target="_blank"
                className="footer-top__icon paragraph"
              >
                <BsFillEnvelopeFill /> contact@vtwebdevelopment.com
              </a>
            </p>
            <p className="footer-top__text">
              <a
                href="tel:802.391.9574"
                target="_blank"
                className="footer-top__icon"
              >
                <IoCall /> 802.391.9574
              </a>
            </p>
          </article>

          <article>
            <div className="footer-top__logo">
              <Link to="/">
                <Image
                  fixed={fixed}
                  fadeIn={false}
                  loading="eager"
                  className="hero-img"
                  alt={alt}
                  className="hero-img"
                />
              </Link>
            </div>

            <nav className="footer__socials">
              {socialLinks &&
                socialLinks.map(item => {
                  return (
                    <ul key={item.id}>
                      <li>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener"
                          className="footer-top__text"
                        >
                          {item.icon}
                        </a>
                      </li>
                    </ul>
                  )
                })}
            </nav>

            <div className="call-to-action-link u-margin-top-big">
              <p className="heading-primary--main u-margin-bottom-small">
                <HiOutlineArrowNarrowRight />
                <Link to="/contact">Start a new project</Link>
              </p>
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default FooterTop
