import React from "react"

import FooterBottom from "./FooterBottom"
import FooterTop from "./FooterTop"

const Footer = () => {
  return (
    <footer id="footer">
      <section className="footer-wrap">
        <FooterTop />
        <FooterBottom />
      </section>
    </footer>
  )
}

export default Footer
