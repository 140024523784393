import React, { useContext } from "react"
import { MdClose } from "react-icons/md"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"

import { Link } from "gatsby"
import { GatsbyContext } from "../context/context"

const Sidebar = () => {
  const { links, isSidebarOpen, toggleSidebar } = useContext(GatsbyContext)
  // console.log(isSidebarOpen)

  return (
    <aside className="sidebar">
      <button
        className="sidebar__close--btn"
        aria-label="sidebar-button"
        onClick={toggleSidebar}
      >
        <MdClose className="sidebar__close--btn--icon" />
      </button>
      <nav>
        <div className="call-to-action-link">
          <p className="heading-primary--main u-margin-bottom-small">
            <HiOutlineArrowNarrowRight />
            <Link to="/contact">Start a new project</Link>
          </p>
        </div>
        <ul className={`${isSidebarOpen ? "sidebar__links" : ""}`}>
          {links.map(link => {
            const { id, text, url } = link
            return (
              <li key={id}>
                <Link to={url} onClick={toggleSidebar}>
                  {text}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
