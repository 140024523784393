export default [
  {
    id: 1,
    label: "Law Firms",
  },
  {
    id: 2,
    label: "Contractors",
  },
  {
    id: 3,
    label: "Restaurants & Bars",
  },
  {
    id: 4,
    label: "Retail",
  },
]
