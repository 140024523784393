import React from "react"
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io5"

export default [
  {
    id: 1,
    icon: <IoLogoFacebook className="icon" />,
    url: "https://www.facebook.com/VT-Web-Development-109507034514575",
  },
  {
    id: 2,
    icon: <IoLogoTwitter className="icon" />,
    url: "https://twitter.com/vtwebdev",
  },
]
