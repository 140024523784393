import React from "react"
import styled from "styled-components"

import Navbar from "./Navbar"

const InnerHero = ({ children }) => {
  return (
    <>
      <Navbar />

      <Wrapper id="inner-hero" className="inner-hero u-margin-bottom-big">
        <header className="inner-hero__header u-center-text">
          <div className="row">
            <div className="col-1-of-1">
              <article className="inner-hero__info u-margin-top">
                {children}
              </article>
            </div>
          </div>
        </header>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  @media only screen and (max-width: 600px) {
    margin-bottom: 0 !important;
  }
`

export default InnerHero
